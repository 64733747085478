<template>
  <div>
    <!-- start of Court & Institution modal -->
    <b-modal id="court-modal" centered hide-footer hide-header size="xl">
      <!-- start of Chosen Court Section -->
      <div class="row">
        <div class="col-auto">
          <b>Chosen Court(s):</b>
        </div>

        <div
          class="col-auto"
          v-if="
            (rJuri_index == null && isAll === 'all' + jurisdiction) ||
            (rJuri_index !== null &&
              research_courtnames[rJuri_index] ==
                'all' + research_jurisdictions[rJuri_index])
          "
        >
          <span
            @click="resetIsAll(rJuri_index)"
            class="court_badge cursor-pointer"
          >
            {{ `All Courts and Institutions in ` }}
            {{
              rJuri_index == null
                ? jurisdiction
                : research_jurisdictions[rJuri_index]
            }}
            <span class="material-icons-outlined">close</span>
          </span>
        </div>

        <div
          v-else-if="rJuri_index == null"
          class="col-auto"
          v-for="(chosen, choose_court_list_idx) in choose_court_list"
          :key="choose_court_list_idx"
        >
          <span @click="removeCourt(chosen)" class="court_badge cursor-pointer">
            {{ chosen.name }}
            <span class="material-icons-outlined">close</span>
          </span>
        </div>

        <div
          v-else-if="(research_courtnames[rJuri_index].length ?? -1) > 0"
          class="col-auto"
          v-for="(chosen, index) in research_courtnames[rJuri_index]"
          :key="index"
        >
          <span
            class="court_badge cursor-pointer"
            @click="removeCourt(chosen, rJuri_index)"
          >
            {{ chosen.name }}
            <span class="material-icons-outlined">close</span>
          </span>
        </div>
      </div>

      <b-form-input
        class="mt-3"
        v-model.trim="choose_court"
        @input="search_choose_court"
        placeholder="Search Court"
        autocorrect="off"
        autocomplete="off"
      ></b-form-input>
      <!-- end of Chosen Court Section -->

      <!-- start of Court List Section -->
      <h5 class="mt-4">
        <b>{{ "Courts and Institutions in " }}
        {{
          rJuri_index == null
            ? jurisdiction
            : research_jurisdictions[rJuri_index]
        }}</b>
      </h5>

      <div class="row juris-modal">
        <div class="col">
          <div v-if="sort_alphabetically == 'all'" class="row">
            <div class="col-12 d-flex align-items-center mb-3">
              <b-form-checkbox
                v-if="rJuri_index == null"
                id="isAll"
                v-model="isAll"
                name="isAll"
                :value="'all' + jurisdiction"
              >
                Choose All Courts & Institutions
                <span style="color: #0d6efd">
                  (Choose this if you don't know which court the case is
                  filed in/ associated with)
                </span>
              </b-form-checkbox>
              <b-form-checkbox
                v-else
                id="RisAll"
                v-model="research_courtnames[rJuri_index]"
                name="RisAll"
                :value="'all' + research_jurisdictions[rJuri_index]"
                @change="handleRAll"
              >
                All Courts & Institutions
                <span style="color: #0d6efd">
                  (Choose this if you don't know which court the case is
                  filed in/ associated with)
                </span>
              </b-form-checkbox>
            </div>
            <div class="col">
              <div
                class="row"
                v-for="(courts, idx) in courts_in_country.courts"
                :key="idx"
              >
                <div class="col mb-2">
                  <h6 class="font-weight-bold">
                    {{ courts.hierarchy | capitalize }}
                  </h6>

                  <b-form-checkbox
                    @change="chooseCourt(item, rJuri_index)"
                    v-model="selectedCourts"
                    :value="item.name"
                    :disabled="
                      isAll ===
                        (rJuri_index == null
                          ? 'all' + jurisdiction
                          : 'all' + research_jurisdictions[rJuri_index]) ||
                      (rJuri_index == null &&
                        selectedCourts.length > 1 &&
                        choose_court_list.length > 1 &&
                        !choose_court_list
                          .map((crt) => crt.name)
                          .includes(item.name)) ||
                      research_courtnames[rJuri_index] ==
                        'all' + research_jurisdictions[rJuri_index] ||
                      (rJuri_index !== null &&
                        !research_courtnames[rJuri_index]?.includes(item) &&
                        (research_courtnames[rJuri_index]?.length ?? -1) >
                          1)
                    "
                    class="font-16 block cursor-pointer"
                    v-for="(item, courts_idx) in courts.courts"
                    :key="courts_idx"
                  >
                    {{ item.name }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="sort_alphabetically == 'search'" class="row">
            <div class="col">
              <div
                class="row"
                v-for="(courts, idx) in courts_in_country.courts"
                :key="idx"
              >
                <div class="col">
                  <b-form-checkbox
                    @change="chooseCourt(item)"
                    v-show="
                      item.name
                        .toLowerCase()
                        .includes(choose_court.toLowerCase())
                    "
                    v-model="selectedCourts"
                    :value="item.name"
                    :disabled="
                      isAll ===
                        (rJuri_index == null
                          ? 'all' + jurisdiction
                          : 'all' + research_jurisdictions[rJuri_index]) ||
                      (rJuri_index == null &&
                        selectedCourts.length > 0 &&
                        choose_court_list.length > 0 &&
                        choose_court_list[0].name !== item.name) ||
                      research_courtnames[rJuri_index] ==
                        'all' + research_jurisdictions[rJuri_index] ||
                      (rJuri_index !== null &&
                        !research_courtnames[rJuri_index]?.includes(item) &&
                        (research_courtnames[rJuri_index]?.length ?? -1) >
                          1)
                    "
                    class="font-16 block cursor-pointer"
                    v-for="(item, courts_idx) in courts.courts"
                    :key="courts_idx"
                  >
                    {{ item.name }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="row">
            <div class="col">
              <div
                class="row"
                v-for="(courts, idx) in courts_in_country.courts"
                :key="idx"
              >
                <div class="col">
                  <b-form-checkbox
                    @change="chooseCourt(item)"
                    v-show="
                      item.name[0].toLowerCase() ==
                      choosen_alpha.toLowerCase()
                    "
                    v-model="selectedCourts"
                    :value="item.name"
                    :disabled="
                      isAll ===
                        (rJuri_index == null
                          ? 'all' + jurisdiction
                          : 'all' + research_jurisdictions[rJuri_index]) ||
                      (rJuri_index == null &&
                        selectedCourts.length > 0 &&
                        choose_court_list.length > 0 &&
                        choose_court_list[0].name !== item.name) ||
                      research_courtnames[rJuri_index] ==
                        'all' + research_jurisdictions[rJuri_index] ||
                      (rJuri_index !== null &&
                        !research_courtnames[rJuri_index]?.includes(item) &&
                        (research_courtnames[rJuri_index]?.length ?? -1) >
                          1)
                    "
                    class="font-16 block cursor-pointer"
                    v-for="(item, courts_idx) in courts.courts"
                    :key="courts_idx"
                  >
                    {{ item.name }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-1">
          <span @click="openAll" class="cursor-pointer text-muted font-14">
            {{ "All" }}
          </span>
          <span
            v-for="(alpha, alphabets_idx) in courts_in_country.Alphabet"
            :key="alphabets_idx"
            @click="choose_alphabet(alpha)"
            class="text-muted cursor-pointer font-14 display-block"
          >
            {{ alpha | capitalize }}
          </span>
        </div>
      </div>
      <!-- end of Court List Section -->

      <!-- start of Footer Section -->
      <button
        @click="finalised_courts('add')"
        class="btn btn-primary float-right px-4 m-2"
      >
        {{ "Done" }}
      </button>

      <button
        @click="finalised_courts('hide')"
        class="btn btn-primary-outlined float-right px-4 m-2"
      >
        {{ "Cancel" }}
      </button>
      <!-- end of Footer Section -->
    </b-modal>
    <!-- end of Court & Institution modal -->

    <div class="page-wrapper-xl">
      <div class="nexcard p-4">
        <!-- start of Form header -->
        <h3 class="modal-card__title text-center mb-4">
          {{ "Pre-trial Phase" }}
        </h3>
        <div class="step__row mb-4" style="max-width: 300px">
          <div class="step__col-auto" id="generalDetailsIcon">
            <img src="@/assets/input-step-icons/icon-step-complete.svg" />
            <span class="step__name">{{ "Phase 1" }}</span>
          </div>
          <hr />
          <div class="step__col-auto" id="letterDetailsIcon">
            <img src="@/assets/input-step-icons/icon-step2-inactive.svg" />
            <span class="step__name">{{ "Phase 2" }}</span>
          </div>
        </div>
        <!-- end of Form header -->

        <div class="general_container">
          <!-- start of Client Case -->
          <b>
            {{ "Client's Case" }}
            <span
              id="tooltip-target-3"
              class="text-danger cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-3"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip3"
            title="Required"
          ></b-tooltip>

          <div class="mt-1 mb-3">
            <b-form-textarea
              v-model.trim="client_case"
              id="textarea"
              :placeholder="`One line explanation of the case being handled. Please state the basic theme of your client's case in not more than 1 sentence.\n\nFor example: \nAlleging three counts of defamation against ex-wife including domestic violence and false allegations.`"
              autocorrect="off"
              autocomplete="off"
              rows="5"
              max-rows="5"
              v-validate="'required'"
              type="text"
              name="Client's Case"
            ></b-form-textarea>
            <small class="text-muted float-right count_length">
              {{ getWordCount(client_case) }} / 50
            </small>

            <!-- error displays -->
            <span
              class="text-danger text-sm"
              v-show="getWordCount(client_case) > 50"
            >
              {{ "Should not be more than 50 words" }}
            </span>
            <span class="text-danger text-sm">
              {{ errors.first("Client's Case") }}
            </span>
          </div>
          <!-- end of Client Case -->

          <!-- start of Client Type -->
          <b>
            Client Type
            <span
              id="tooltip-target-4"
              class="text-danger font-weight-bold cursor-pointer"
            >*</span>
          </b>
          <b-tooltip
            target="tooltip-target-4"
            triggers="hover"
            placement="top"
            variant="secondary"
            id="tooltip4"
            title="Required"
          ></b-tooltip>

          <div class="mt-1 mb-3">
            <b-form-select
              class="form-select w-100 h-100 rounded"
              :class="{ 'text-muted': client_type === '' }"
              id="inputGroupSelect04"
              aria-label="Example select with button addon"
              v-model="client_type"
              v-validate="'required'"
              name="Client Type"
            >
              <b-form-select-option value="" disabled selected>
                {{ "Please Select a Client Type" }}
              </b-form-select-option>
              <b-form-select-option
                class="text-softblack"
                :value="item"
                :key="index"
                v-for="(item, index) in ['petitioner', 'respondent']"
              >
                {{ item | capitalize }}
              </b-form-select-option>
            </b-form-select>
            <span class="text-danger text-sm">
              {{ errors.first("Client Type") }}
            </span>
          </div>
          <!-- end of Client Type -->

          <!-- start of Jurisdiction & Court and Institutions -->
          <div class="mt-2 d-flex align-items-center">
            <b>Main Jurisdiction and Court</b>
            <span
              id="tooltip-target-1"
              class="span_badge material-icons-outlined cursor-pointer"
            >
              help_outline
            </span>
            <b-tooltip
              target="tooltip-target-1"
              triggers="hover"
              placement="righttop"
              variant="secondary"
              custom-class="LESInputTooltip"
            >
              The jurisdiction and court in which the case is filed or
              associated with
            </b-tooltip>
            <span
              id="tooltip-target-5"
              class="text-danger font-weight-bold cursor-pointer"
            >*</span>
            <b-tooltip
              target="tooltip-target-5"
              triggers="hover"
              placement="top"
              variant="secondary"
              id="tooltip5"
              title="Required"
            ></b-tooltip>
          </div>

          <div class="row mt-2 mb-3">
            <!-- Jurisdiction -->
            <div class="col">
              <b-form-select
                class="form-select w-100 rounded"
                :class="{ 'text-muted': jurisdiction === '' }"
                id="inputGroupSelect04"
                aria-label="Example select with button addon"
                v-model="jurisdiction"
                @change="getCourts(jurisdiction)"
                name="Juri"
                v-validate="'required'"
              >
                <b-form-select-option value="" disabled selected>
                  {{ "Please Select a Country" }}
                </b-form-select-option>
                <b-form-select-option
                  class="text-softblack"
                  v-for="(item, index) in jurisdictions"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </b-form-select-option>
              </b-form-select>
            </div>

            <!-- Court & Institution -->
            <div class="col-7" @click="openJurisdiction()">
              <div :class="[
                'd-flex justify-content-center h-100 card px-1',
                { 'cursor-pointer': this.jurisdiction.length > 0 },
              ]">
                <p
                  class="text-muted my-0 ms-1"
                  v-if="finalised_choose_courts.length == 0"
                >
                  {{
                    this.jurisdiction.length == 0
                      ? "Please Select a Jurisdiction"
                      : "Click to Select Court"
                  }}
                </p>

                <template v-else>
                  <span
                    v-for="(court, index) in finalised_choose_courts"
                    :key="index"
                    class="d-inline court_badge coursor-pointer my-1 mx-1"
                    @click.stop="removeCourt(court, null)"
                  >
                    {{ court.name }}
                    <span class="material-icons-outlined">close</span>
                  </span>
                </template>
              </div>
            </div>

            <div
              class="col-12"
              v-if="finalised_choose_courts.length === 0 && errors.has('Juri')"
            >
              <span class="text-danger text-sm">
                {{ "The Main Jurisdiction and Court field is required" }}
              </span>
            </div>
          </div>
          <!-- end of Jurisdiction & Court and Institutions -->

          <!-- start of Lawyer Name -->
          <div class="d-flex align-items-center" v-if="lawyer_name_format?.[jurisdiction]">
            <b>Adverse Lawyer Name</b>
            <span
              id="tooltip-target-7"
              class="span_badge material-icons-outlined cursor-pointer"
            >
              help_outline
            </span>
            <b-tooltip
              target="tooltip-target-7"
              trigger="hover"
              placement="righttop"
              variant="secondary"
              custom-class="LESInputTooltip"
            >
              To generate Adverse Lawyer analysis (Optional)
            </b-tooltip>
          </div>
          <div class="row mt-2" v-if="lawyer_name_format?.[jurisdiction]">
            <div class="col" v-if="lawyer_name_format[jurisdiction][0] == 1">
              <b-form-input
                class="mb-3"
                placeholder="First Name"
                v-model.trim="lawyer.first_name"
              ></b-form-input>
            </div>
            <div class="col" v-if="lawyer_name_format[jurisdiction][1] == 1">
              <b-form-input
                class="mb-3"
                placeholder="Middle Name Initial"
                v-model.trim="lawyer.middle_name"
              ></b-form-input>
            </div>
            <div class="col" v-if="lawyer_name_format[jurisdiction][2] == 1">
              <b-form-input
                class="mb-3"
                placeholder="Surname"
                v-model.trim="lawyer.last_name"
              ></b-form-input>
            </div>
          </div>
          <!-- end of Lawyer Name -->

          <!-- start of Judge Name & Title -->
          <div class="d-flex align-items-center" v-if="judge_name_format?.[jurisdiction]">
            <b>Judge Name</b>
            <span
              id="tooltip-target-8"
              class="span_badge material-icons-outlined cursor-pointer"
            >
              help_outline
            </span>
            <b-tooltip
              target="tooltip-target-8"
              trigger="hover"
              placement="righttop"
              variant="secondary"
              custom-class="LESInputTooltip"
            >
              To generate Judge analysis (Optional)
            </b-tooltip>
          </div>
          <div class="row mt-2" v-if="judge_name_format?.[jurisdiction]">
            <div class="col" v-if="jurisdiction == 'Canada'">
              <select
                class="mb-3 form-select"
                :class="{ 'text-muted': judge_title == null }"
                placeholder="Title"
                v-model="judge_title"
              >
                <option class="mt-2" value="null" disabled selected>
                  Judge Title
                </option>
                <option
                  class="mt-2 text-softblack"
                  v-for="(title, index) in judge_titles"
                  :key="index"
                  :value="title"
                >
                  {{ title }}
                </option>
              </select>
            </div>
            <div class="col" v-if="judge_name_format[jurisdiction][0] == 1">
              <b-form-input
                class="mb-3"
                placeholder="First Name"
                v-model.trim="judge.first_name"
              ></b-form-input>
            </div>
            <div class="col" v-if="judge_name_format[jurisdiction][1] == 1">
              <b-form-input
                class="mb-3"
                placeholder="Middle Name Initial"
                v-model.trim="judge.middle_name"
              ></b-form-input>
            </div>
            <div class="col" v-if="judge_name_format[jurisdiction][2] == 1">
              <b-form-input
                class="mb-3"
                placeholder="Surname"
                v-model.trim="judge.last_name"
              ></b-form-input>
            </div>
          </div>
          <!-- end of Judge Name & Title -->

          <!-- start of Research Jurisdictions -->
          <template v-if="jurisdiction && jurisdiction !== 'United States of America'">
            <div class="d-flex align-items-center">
              <b>Number of Research Jurisdiction(s) and Court(s):</b>
              <span
                id="tooltip-target-2"
                class="span_badge material-icons-outlined cursor-pointer"
              >
                help_outline
              </span>
              <b-tooltip
                target="tooltip-target-2"
                trigger="hover"
                placement="righttop"
                variant="secondary"
                custom-class="LESInputTooltip"
              >
                Choose jurisdictions to get resources for the case
              </b-tooltip>
            </div>
            <div
              class="card p-2 mt-1"
              v-for="(juri, index) in research_jurisdictions"
              :key="index"
            >
              <div class="row">
                <div class="col-4 d-flex align-items-start">
                  <p style="margin-top: 7px" class="mb-0 mx-2">
                    {{ index + 1 }}.
                  </p>
                  <b-form-select
                    id="inputGroupSelect04"
                    :class="[
                      'form-select w-100 mb-0 rounded',
                      { 'text-muted': !research_jurisdictions[index] }
                    ]"
                    name="Research Jurisdictions"
                    v-model="research_jurisdictions[index]"
                    @change="openJurisdiction(index)"
                  >
                    <b-form-select-option value="" disabled selected>
                      {{ "Please Select a Country" }}
                    </b-form-select-option>
                    <b-form-select-option
                      class="text-softblack"
                      v-for="(item, idx) in rJuriOptions[index]"
                      :key="idx"
                      :value="item"
                    >
                      {{ item }}
                    </b-form-select-option>
                  </b-form-select>
                </div>

                <div class="col" @click="openJurisdiction(index)">
                  <div
                    :class="[
                      'd-flex justify-content-center h-100 card px-1',
                      { cursor_pointer: juri?.length > 0 },
                    ]"
                  >
                    <p
                      v-if="research_finalcourt[index].length == 0"
                      class="text-muted my-0 ms-1"
                    >
                      {{
                        !juri
                          ? "Please Select a Jurisdiction"
                          : "Click to Select Court"
                      }}
                    </p>

                    <span
                      v-else-if="
                        research_finalcourt[index] ==
                        'all' + research_jurisdictions[index]
                      "
                      class="court_badge cursor-pointer my-1"
                      @click.stop="
                        removeCourt(
                          'all' + research_jurisdictions[index],
                          index
                        )
                      "
                    >
                      {{
                        "All Courts and Institutions in " +
                        research_jurisdictions[index]
                      }}
                      <span class="material-icons-outlined">close</span>
                    </span>

                    <span
                      v-else
                      v-for="(court, idx) in research_finalcourt[index]"
                      :key="idx"
                      class="court_badge cursor-pointer my-1"
                      @click.stop="removeCourt(court, index)"
                    >
                      {{ court.name }}
                      <span class="material-icons-outlined">close</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- end of Research Jurisdictions -->

          <!-- start of Background/Facts of The Case >> Pre-event Findings -->
          <div class="mt-3">
            <b>
              {{ "Background/Facts of The Case" }}
              <span
                id="tooltip-target-6"
                class="text-danger cursor-pointer"
              >*</span>
            </b>
            <b-tooltip
              target="tooltip-target-6"
              triggers="hover"
              placement="top"
              variant="secondary"
              id="tooltip6"
              title="Required"
            ></b-tooltip>
          </div>
          <div class="card p-3 mt-2">
            <b>Upload File</b>
            <input
              class="form-control mt-2"
              type="file"
              id="formFile"
              ref="PreEventFile"
              accept=".pdf,.docx"
              @change="handleFileChange"
            />
            <small
              class="font-12 mx-1 text-center d-flex align-items-center"
            >
              <b-icon class="mx-1" icon="info-circle" />
              Please upload PDF or Document file only
            </small>
            
            <div class="w-100 d-flex justify-content-center">
              <hr id="longLine" />
              <b class="mt-1">OR</b>
              <hr id="longLine" />
            </div>

            <b>Text Input</b>
            <div>
              <b-form-textarea
                id="textarea"
                class="mt-1"
                :placeholder="`All the relevant information about the case including but not limited to: \nEvent Timeline, Pre-incident Phase, Post-Incident Phase, Incident Phase, Investigation and Legal Proceedings Phase, evidences, witnesses, damages, relief sought and factual background of the case. \n\nFor example: \nFin, as plaintiff, filed a complaint of defamation against defendant Kate claiming $5 million in damages; Kate filed counterclaims against Fin claiming $10 million in damages. Fin and Kate first met in 2009, and got married in February 2015. In May 2016, at an early stage in their divorce proceedings, Kate claimed that Fin had abused her physically, which he denied. Further she posted a news about the abuse which defamed Fin. Fin wishes to open a case of defamation and domestic violence against Kate.`"
                autocorrect="off"
                autocomplete="off"
                rows="12"
                max-rows="12"
                type="text"
                name="Background/Facts of The Case"
                v-model.trim="pre_event_finding"
                v-validate="'required'"
              ></b-form-textarea>
              <small class="text-muted float-right count_length">
                {{ getWordCount(pre_event_finding) }} / 10000
              </small>

              <span
                class="text-danger text-sm"
                v-show="getWordCount(pre_event_finding) > 10000"
              >
                {{ "Should not be more than 10000 words" }}
              </span>
            </div>
          </div>
          <span
            class="text-danger text-sm"
            v-if="!pre_event_file && errors.has('Background/Facts of The Case')"
          >
            {{ "The Background/Facts of The Case field is required" }}
          </span>
          <!-- end of Background/Facts of The Case >> Pre-event Findings -->

          <!-- start of Witness Client => Client's Witness -->
          <div class="row mt-3">
            <div class="col-9 d-flex align-items-center">
              <b>Number of Client's Witness:</b>
              <span
                id="tooltip-target-9"
                class="span_badge material-icons-outlined cursor-pointer"
              >
                help_outline
              </span>
              <b-tooltip
                target="tooltip-target-9"
                trigger="hover"
                placement="righttop"
                variant="secondary"
                custom-class="LESInputTooltip"
              >
                To generate Witness analysis
              </b-tooltip>
            </div>

            <div class="col d-flex">
              <b-form-input
                v-model="wc_number"
                placeholder="#"
                autocorrect="off"
                autocomplete="off"
                v-validate="'required'"
                type="number"
                name="number"
                @keyup.enter="
                  (event) => {
                    event.target.blur();
                  }
                "
                @blur="updateArr('wc')"
                disabled
              ></b-form-input>
              <b-button class="increment w-30 mx-1" @click="increment('wc')">+</b-button>
              <b-button class="decrement w-30" @click="decrement('wc')">-</b-button>
            </div>

            <div class="col-12 text-danger d-flex justify-content-end">
              <small v-if="wc_number <= 0">
                Leave the field empty if there is no Client's Witness
              </small>
              <small v-if="wc_number > 10">
                Only 10 Client's Witnesses are allowed
              </small>
            </div>
          </div>
          <div class="accordion mt-2" role="tablist">
            <b-card
              no-body
              class="mb-1"
              v-for="(item, index) in witness_client"
              :key="index"
            >
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  v-b-toggle="'witness-client-' + index"
                  class="accordion-header d-flex align-items-center py-0"
                >
                  <div class="name">
                    {{
                      item.name == ""
                        ? "Client's Witness " + (index + 1)
                        : index + 1 + ". " + item.name
                    }}
                  </div>

                  <div
                    class="redbtn btn"
                    @click="clearArrData('wc', index)"
                    v-if="item.statement !== '' || item.name !== ''"
                    v-b-tooltip.hover
                    title="Clear"
                    v-b-tooltip.noninteractive
                  >
                    <i class="bi bi-eraser-fill"></i>
                  </div>

                  <div
                    class="redbtn btn"
                    @click="removeArrData('wc', index)"
                    v-if="witness_client.length > 1 || witness_client.name"
                    v-b-tooltip.hover
                    title="Delete"
                    v-b-tooltip.noninteractive
                  >
                    <i class="bi bi-trash-fill"></i>
                  </div>

                  <span class="material-icons-outlined arrow-icon">
                    expand_less
                  </span>
                </b-button>
              </b-card-header>
              <b-collapse
                :id="'witness-client-' + index"
                :visible="index === finalWC"
                accordion="witness-client-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-form-input
                    v-model.trim="item.name"
                    placeholder="Name"
                    autocorrect="off"
                    autocomplete="off"
                    type="text"
                  ></b-form-input>

                  <div>
                    <b-form-textarea
                      id="textarea"
                      class="mt-3"
                      placeholder="Statement"
                      autocorrect="off"
                      autocomplete="off"
                      rows="3"
                      max-rows="3"
                      type="text"
                      :name="'witness_client_statement_' + index"
                      v-model.trim="item.statement"
                    ></b-form-textarea>
                    <small class="text-muted float-right count_length">
                      {{ getWordCount(item.statement) }} / 1000
                    </small>
                    <span
                      class="text-danger text-sm"
                      v-show="getWordCount(item.statement) > 1000"
                    >
                      {{ "Should not be more than 1000 words" }}
                    </span>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <!-- end of Witness Client -->

          <!-- start of Witness Opposing => Adverse Witness -->
          <div class="row mt-4">
            <div class="col-9 d-flex align-items-center">
              <b>Number of Adverse Witness: </b>
              <span
                id="tooltip-target-10"
                class="span_badge material-icons-outlined cursor-pointer"
              >
                help_outline
              </span>
              <b-tooltip
                target="tooltip-target-10"
                trigger="hover"
                placement="righttop"
                variant="secondary"
                custom-class="LESInputTooltip"
              >
                To generate Adverse Witness analysis
              </b-tooltip>
            </div>
            <div class="col d-flex">
              <b-form-input
                v-model="wo_number"
                placeholder="#"
                autocorrect="off"
                autocomplete="off"
                v-validate="'required'"
                type="number"
                name="number"
                @keyup.enter="
                  (e) => {
                    e.target.blur();
                  }
                "
                @blur="updateArr('wo')"
                disabled
              ></b-form-input>
              <b-button class="increment w-30 mx-1" @click="increment('wo')">+</b-button>
              <b-button class="decrement w-30" @click="decrement('wo')">-</b-button>
            </div>

            <div class="col-12 text-danger d-flex justify-content-end">
              <small v-if="wo_number <= 0">
                Leave the field empty if there is no Adverse Witness
              </small>
              <small v-if="wo_number > 10">
                Only 10 Adverse Witnesses are allowed
              </small>
            </div>
          </div>
          <div class="accordion mt-2" role="tablist">
            <b-card
              no-body
              class="mb-1"
              v-for="(item, index) in witness_opposing"
              :key="index"
            >
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  v-b-toggle="'witness-opposing-' + index"
                  class="accordion-header d-flex align-items-center py-0"
                >
                  <div class="name">
                    {{
                      item.name == ""
                        ? "Adverse Witness " + (index + 1)
                        : index + 1 + ". " + item.name
                    }}
                  </div>

                  <div
                    class="btn redbtn"
                    @click="clearArrData('wo', index)"
                    v-if="item.statement !== '' || item.name !== ''"
                    v-b-tooltip.hover
                    title="Clear"
                    v-b-tooltip.noninteractive
                  >
                    <i class="bi bi-eraser-fill"></i>
                  </div>

                  <div
                    class="btn redbtn"
                    @click="removeArrData('wo', index)"
                    v-if="witness_opposing.length > 1 || witness_opposing.name"
                    v-b-tooltip.hover
                    title="Delete"
                    v-b-tooltip.noninteractive
                  >
                    <i class="bi bi-trash-fill"></i>
                  </div>

                  <span class="material-icons-outlined arrow-icon">
                    expand_less
                  </span>
                </b-button>
              </b-card-header>
              <b-collapse
                :id="'witness-opposing-' + index"
                :visible="index == finalWO"
                accordion="witness-opposing-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-form-input
                    v-model.trim="item.name"
                    placeholder="Name"
                    autocorrect="off"
                    autocomplete="off"
                    type="text"
                  ></b-form-input>

                  <div>
                    <b-form-textarea
                      id="textarea"
                      class="mt-3"
                      placeholder="Statement"
                      autocorrect="off"
                      autocomplete="off"
                      rows="3"
                      max-rows="3"
                      type="text"
                      :name="'witness_opposing_statement_' + index"
                      v-model.trim="item.statement"
                    ></b-form-textarea>
                    <small class="text-muted float-right count_length">
                      {{ getWordCount(item.statement) }} / 1000
                    </small>

                    <span
                      class="text-danger text-sm"
                      v-show="getWordCount(item.statement) > 1000"
                    >
                      {{ "Should not be more than 1000 words" }}
                    </span>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <!-- end of Witness Opposing -->

          <!-- start of Evidences -->
            <div v-if="showEvidenceInputs" class="mt-4 d-flex align-items-center">
              <b>Client's Evidences</b>
              <span
                id="tooltip-target-11"
                class="span_badge material-icons-outlined cursor-pointer"
              >
                help_outline
              </span>
              <b-tooltip
                target="tooltip-target-11"
                trigger="hover"
                placement="righttop"
                variant="secondary"
                custom-class="LESInputTooltip"
              >
                To generate Evidence Analysis
              </b-tooltip>
            </div>
            <input
              v-if="showEvidenceInputs"
              id="cevidence"
              ref="cevidence"
              class="form-control mt-2"
              type="file"
              multiple
              accept=".png,.jpeg,.jpg,.pdf"
              data-max-files="10"
              @change="uploadEvidence($event, 'ce')"
            />
            <small v-if="showEvidenceInputs" class="font-12 text-center d-flex align-items-center">
              <b-icon icon="info-circle" class="mx-1"/>
              Please upload .pdf, .png, .jpeg, .jpg file only (maximum 10 files)
            </small>

            <div v-if="showEvidenceInputs" class="mt-4 d-flex align-items-center">
              <b>Adverse Evidences</b>
              <span
                id="tooltip-target-12"
                class="span_badge material-icons-outlined cursor-pointer"
              >
                help_outline
              </span>
              <b-tooltip
                target="tooltip-target-12"
                trigger="hover"
                placement="righttop"
                variant="secondary"
                custom-class="LESInputTooltip"
              >
                To generate Adverse Evidence Analysis
              </b-tooltip>
            </div>
            <input
              v-if="showEvidenceInputs"
              id="aevidence"
              ref="aevidence"
              class="form-control mt-2"
              type="file"
              multiple
              accept=".png,.jpeg,.jpg,.pdf"
              data-max-files="10"
              @change="uploadEvidence($event, 'ae')"
            />
            <small v-if="showEvidenceInputs" class="font-12 text-center d-flex align-items-center">
              <b-icon icon="info-circle" class="mx-1"/>
              Please upload .pdf, .png, .jpeg, .jpg file only (maximum 10 files)
            </small>
          <!-- end of Evidences -->

          <div style="height: 20px">
            <button
              :disabled="isSubmitting"
              @click="sendValue"
              class="btn-primary btn float-right px-5 my-4"
            >
              {{ "Next" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import LegalExpertSystem from "@/store/LES.js";
import { courts } from "../CaseSearch/courtnames";
import { getWordCount, groupValidateInput, validateInput } from "../../store/utils";

export default {
  data() {
    return {
      isSubmitting: false,
      showEvidenceInputs: true,
      
      client_case: "",
      client_type: "",

      jurisdiction: "",
      jurisdictions: [
        "United States of America",
        "United Kingdom",
        "Australia",
        "Canada",
        "New Zealand",
        "Singapore",
        "Malaysia",
      ],

      sort_alphabetically: "all",
      exceededTone: false,
      chosen_tone_value: "",
      isAll: "",
      choose_court: "",

      choosen_alpha: "",
      courts: courts,
      courts_in_country: [],

      selectedCourts: [],
      choose_court_list: [],
      finalised_choose_courts: [],

      rJuri_number: 0,
      rJuri_index: null,
      research_jurisdictions: [],
      research_courtnames: [],
      research_finalcourt: [],

      lawyer_name_format: {
        "United States of America": [1, 1, 1],
        Australia: [1, 1, 1],
        "New Zealand": [1, 1, 1],

        "United Kingdom": [1, 0, 1],
        Singapore: [1, 0, 1],

        "": null,
      },
      // first_name: null,
      // middle_name: null,
      // last_name: null,
      lawyer: {
        first_name: null,
        middle_name:null,
        last_name: null,
        type: "lawyer",
        title: "lawyer",
      },
      judge: {
        first_name: null,
        middle_name:null,
        last_name: null,
        type: "Judge",
        title: "Judge",
      },

      judge_name_format: {
        "United States of America": [1, 1, 1],

        "United Kingdom": [1, 0, 1],
        "New Zealand": [1, 0, 1],
        Canada: [1, 0, 1],

        "": null,
      },
      // first_name: null,
      // middle_name: null,
      // middle_name: null,
      // judge_title: null,
      judge_titles: [
        "C.J.C",
        "C.C.J",
        "P.J.C",
        "S.C.C",
        "P.J",
        "J.P",
        "C.J",
        "J.",
      ],

      pre_event_finding: "",
      pre_event_file: null,

      dataKey: {
        wo: {
          arr: "witness_opposing",
          num: "wo_number",
        },
        wc: {
          arr: "witness_client",
          num: "wc_number",
        },
      },

      wo_number: 1,
      wc_number: 1,
      witness_opposing: [{ name: "", statement: "" }],
      witness_client: [{ name: "", statement: "" }],

      cevid_number: 1,
      aevid_number: 1,
    };
  },

  computed: {
    rJuriOptions() {
      const array = [];
      for (var i = 0; i < 5; i++) {
        array.push(
          this.jurisdictions.filter(
            (juri) =>
              juri !== "United States of America" &&
              juri !== this.jurisdiction &&
              (!this.research_jurisdictions.includes(juri) ||
                this.research_jurisdictions[i] == juri)
          )
        );
      }
      return array;
    },

    finalWO() {
      return this.witness_opposing.length - 1;
    },
    finalWC() {
      return this.witness_client.length - 1;
    },
    finalCE() {
      return this.client_evidences.length - 1;
    },
    finalAE() {
      return this.adverse_evidences.length - 1;
    },
  },

  methods: {
    getWordCount(text) { return getWordCount(text); },

    handleFileChange(event) {
      var result = validateInput(event.target.files[0], "file", ["pdf", "docx"]);
      if (!result[0] && result[1] !== "empty") {
        this.toastError(result[1]);
        this.pre_event_file = null;
        this.$refs.PreEventFile.value = null;
      } else {
        this.pre_event_file = result[1] === "empty"
          ? null : result[1];
      }
    },

    choose_alphabet(alp) {
      this.sort_alphabetically = "alpha";
      this.choose_court = "";
      this.choosen_alpha = alp;
    },
    search_choose_court() {
      this.sort_alphabetically =
        this.choose_court.length == 0 ? "all" : "search";
      this.choosen_alpha = "";
    },
    openAll() {
      this.sort_alphabetically = "all";
      this.choose_court = "";
      this.choosen_alpha = "";
    },

    resetIsAll(index = null) {
      if (index !== null) {
        this.$set(this.research_courtnames, index, []);
        this.selectedCourts = [];
      } else {
        this.isAll = "";
        this.exceededTone = false;
        this.finalised_choose_courts = [];
      }
    },
    getCourts(crt) {
      this.research_jurisdictions = [];
      this.research_courtnames = [];
      this.research_finalcourt = [];

      this.rJuri_number = 1;
      this.updateRJuri();

      this.sort_alphabetically = "all";
      this.courts_in_country = [];
      this.choose_court_list = [];
      this.finalised_choose_courts = [];

      this.courts_in_country = this.courts[crt];
      this.$bvModal.show("court-modal");

      this.resetNames();
    },

    handleRAll() {
      const allcourts = this.research_courtnames[this.rJuri_index];
      if (
        allcourts[allcourts.length - 1] ==
        "all" + this.research_jurisdictions[this.rJuri_index]
      ) {
        this.research_courtnames[this.rJuri_index] = [
          allcourts[allcourts.length - 1],
        ];
        this.selectedCourts = allcourts;
      } else {
        this.research_courtnames[this.rJuri_index] = [];
        this.selectedCourts = [];
      }
    },

    openJurisdiction(index = null) {
      if (
        (index == null && this.jurisdiction.length == 0) ||
        this.research_jurisdictions[index]?.length == 0
      ) {
        return;
      }

      this.rJuri_index = index;
      this.courts_in_country =
        this.courts[this.research_jurisdictions[index] ?? this.jurisdiction];

      this.selectedCourts =
        [
          ...(index == null
            ? this.finalised_choose_courts
            : this.research_courtnames[index]
          )?.map((crt) => crt.name),
        ] ?? [];

      this.$bvModal.show("court-modal");
    },

    removeCourt(court, index = null) {
      if (index == null && this.isAll === "all" + this.jurisdiction) {
        this.resetIsAll();
      } else if (index == null) {
        this.choose_court_list = this.choose_court_list.filter(
          (crt) => crt !== court
        );
        this.selectedCourts = this.choose_court_list.map((crt) => crt.name);
        this.finalised_choose_courts = this.choose_court_list;
      } else {
        this.selectedCourts = [];
        this.research_courtnames[index] = this.research_courtnames[
          index
        ].filter((crt) => crt !== court);
        this.research_finalcourt[index] = [...this.research_courtnames[index]];
      }
    },

    finalised_courts(flag) {
      if (this.rJuri_index !== null) {
        if (flag == "add") {
          this.research_finalcourt = [...this.research_courtnames];

          if (
            this.rJuri_number < 5 &&
            this.rJuri_index == this.rJuri_number - 1
          ) {
            this.rJuri_number++;
            this.updateRJuri();
          }
        }

        this.rJuri_index = null;
        this.$bvModal.hide("court-modal");
        return;
      }

      this.openAll();
      if ((flag = "add")) {
        if (this.isAll == "all" + this.jurisdiction) {
          const allCourt = {
            id: this.isAll,
            name: "All Courts and Institutions in " + this.jurisdiction,
          };
          this.choose_court_list = [allCourt];
        }
        // console.log(this.choose_court_list);
        this.finalised_choose_courts = this.choose_court_list;
      }
      this.rJuri_index = null;
      this.$bvModal.hide("court-modal");
    },

    chooseCourt(crt, index = null) {
      if (index !== null) {
        if (this.research_courtnames[index]?.includes(crt) ?? false) {
          this.research_courtnames[index] = this.research_courtnames[
            index
          ].filter((item) => item !== crt);
        } else if (this.research_courtnames[index].length < 2) {
          if (Array.isArray(this.research_courtnames[index])) {
            this.research_courtnames[index].push(crt);
          } else {
            this.research_courtnames[index] = [crt];
          }
        }
        this.selectedCourts = this.research_courtnames[index];
        return;
      }

      const isChecked = this.selectedCourts?.includes(crt.name);
      if (isChecked) {
        // If checked, add to the choose_court_list array
        if (this.choose_court_list.length < 2) {
          if (this.isAll === "all" + this.jurisdiction) {
            this.exceededTone = true;
            this.choose_court_list = [];
            this.selectedCourts = [];
          } else {
            this.choose_court_list.push(crt);
          }
        } else {
          this.exceededTone = true;
        }
      } else {
        // If unchecked, remove from the choose_court_list array
        const index = this.choose_court_list.findIndex(
          (court) => court.name === crt.name
        );
        if (index !== -1) {
          this.choose_court_list.splice(index, 1);
        }
        this.exceededTone = false;
      }
      // console.log(this.selectedCourts);
      // console.log(this.choose_court_list);
    },

    updateRJuri() {
      const rJuriLength = this.research_jurisdictions.length;
      if (rJuriLength < this.rJuri_number) {
        for (let i = 0; i < this.rJuri_number - rJuriLength; i++) {
          this.research_jurisdictions.push("");
          this.research_courtnames.push([]);
          this.research_finalcourt.push([]);
        }
      } else if (rJuriLength > this.rJuri_number) {
        this.research_jurisdictions.splice(this.rJuri_number);
        this.research_courtnames.splice(this.rJuri_number);
        this.research_finalcourt.splice(this.rJuri_number);
      }
    },

    resetNames() {
      this.first_name = null;
      this.middle_name = null;
      this.last_name = null;
      this.first_name = null;
      this.middle_name = null;
      this.last_name = null;
      this.judge_title = null;
    },

    clearArrData(flag, index) {
      this.$set(
        this[this.dataKey[flag].arr],
        index,
        {
          name: "",
          statement: "",
        }
      );
    },
    removeArrData(flag, index) {
      const KEY = this.dataKey[flag];
      this[KEY.arr].splice(index, 1);
      this[KEY.num] = this[KEY.arr].length;
    },
    updateArr(flag) {
      const KEY = this.dataKey[flag];
      const ArrLength = this[KEY.arr].length;

      if (this[KEY.num] < 1 || this[KEY.num] > 10) {
        this[KEY.num] = ArrLength;
        return;
      }

      // increase
      if (this[KEY.num] > ArrLength) {
        for (let i = 0; i < this[KEY.num] - ArrLength; i++) {
          setTimeout(() => {
            this[KEY.arr].push({
              name: "",
              statement: "",
            });
          }, 30 * i);
        }
      }
      // decrease
      else if (this[KEY.num] < ArrLength) {
        for (let i = ArrLength; i > this[KEY.num]; i--) {
          this[KEY.arr].splice(this[KEY.arr].length - 1);
        }
      }
    },

    uploadEvidence(e, flag) {
      if (e.target.files.length > 10) {
        document.getElementById(`${flag}vidence`).value = "";
        this.toastError("max-evidence");
        return;
      }

      const Files = Array.from(e.target.files).map(
        (img) => validateInput(img, "file", ["png", "jpeg", "jpg", "pdf"])
      );
      if (Files.findIndex(x => !x[0]) > -1) {
        document.getElementById(`${flag}vidence`).value = "";
        this.toastError("evidence");
      }
    },

    parseCollectionValue(original) {
      return JSON.stringify(
        original.reduce((acc, obj) => {
          acc[obj.name] = obj.statement;
          return acc;
        }, {})
      );
    },

    toastError(flag) {
      this.$toast.error({
        "empty": "Please fill in all required fields",
        "max": "Maximum word limit exceeded",
        "missing": "Please fill in all fields for each witness",
        "pre_event": "Please upload file OR input text for Background/Facts of The Case",
        "courts": "Please select court(s) for each chosen jurisdiction",
        "file": "Please upload a .pdf or .docx file",

        "evidence": "Please upload .pdf, .png, .jpeg or .jpg files",
        "max-evidence": "Maximum evidence limit (10) exceeded"
      }[flag]);
    },

     async sendValue() {

      // Prevent submission if already in progress
      if (this.isSubmitting) return;

      // Set isSubmitting to true to disable button
      this.isSubmitting = true;
      console.log("Goto", this.isSubmitting);

      try {
        // Validate all fields
        await this.$validator.validateAll();

        // Group jurisdiction & courts
        var jurisdiction = {};
        jurisdiction[this.jurisdiction] = this.finalised_choose_courts;
        this.research_jurisdictions.map((juri, id) => {
          jurisdiction[juri] = this.research_courtnames[id];
        });
        const Results = groupValidateInput([
          [this.client_case, "string", ["max50"]],
          [this.client_type, "string"],
          [jurisdiction, "courts", ["LES"]],
          [this.pre_event_finding, "string", ["max10000"]],
          [this.witness_client, "collection"],
          [this.witness_opposing, "collection"],]);

        for (let i = 0; i < Results.length; i++) {
          if (
            (i < 3 && !Results[i][0]) || // General result check for required fields
            (i > 3 && !Results[i][0] && Results[i][1] !== "empty") // General result check for optional fields
          ) {
            this.toastError(Results[i][1]);
            this.isSubmitting = false;
            return;
          }
        }
        // Extra check for pre-event findings
        var pre_event = Results[3];
        if (this.pre_event_finding && this.pre_event_file) {
          this.toastError("pre_event");
          this.isSubmitting = false;
          return;
        } else if (
          (!pre_event[0] && pre_event[1] === "max") ||
          (!pre_event[0] && pre_event[1] === "empty" && !this.pre_event_file)
        ) {
          this.toastError(pre_event[1]);
          this.isSubmitting = false;
          return;
        } else {
          pre_event[1] = pre_event[0] ? pre_event[1] : this.pre_event_file;
        }
        let lawyer_name = this.lawyer.first_name && this.lawyer.last_name ? {
          first_name: this.lawyer.first_name + (this.lawyer.middle_name ? ' ' + this.lawyer.middle_name : ''),
          last_name: this.lawyer.last_name,
          type: this.lawyer.type || 'lawyer',
          title: this.lawyer.title || 'lawyer' 
        } : null;
        let judge_name = this.judge.first_name && this.judge.last_name ? {
          first_name: this.judge.first_name + (this.judge.middle_name ? ' ' + this.judge.middle_name : ''),
          last_name: this.judge.last_name,
          type: this.judge.type || 'judge',
          title: this.judge.title || 'judge' 
        } : null;
        let client_evidences = this.showEvidenceInputs ? document.getElementById("cevidence").files : [];
        let evidence_client_file = Array.from(client_evidences).map(file => file.name);

        let adverse_evidences = this.showEvidenceInputs ? document.getElementById("aevidence").files : [];
        let evidence_opposing_file = Array.from(adverse_evidences).map(file => file.name);
        if (
          pre_event[0] &&
          evidence_client_file.length === 0 &&
          evidence_opposing_file.length === 0
        ) {
          let obj = {
            client_case: Results[0][1],
            client_type: Results[1][1],
            jurisdiction: Results[2][1],
            pre_event: pre_event[1],
            language: "en",
            location_id: 1
          };
          if (lawyer_name) obj.lawyer = JSON.stringify(lawyer_name);
          if (judge_name) obj.judge = JSON.stringify(judge_name);
          if (this.witness_opposing.every(e => e.name !== '' && e.statement !== '')) {
            obj.witness_opposing = Results[5][1].map((item) => {
              return JSON.stringify({[item.name]: item.statement});
            });
          } 
          if (this.witness_client.every(e => e.name !== '' && e.statement !== '')) {
            obj.witness_client = Results[4][1].map((item) => {
              return JSON.stringify({[item.name]: item.statement});
            });
          }

          const response = await LegalExpertSystem.LesCreatePreTrial(obj);
          this.$router.push({
            name: "LegalExpertSystemPreTrialResult",
            params: {
              id: response.data.data?.id,
              category: "preTrial"
            }
          });
        } else {
          let bodyFormData = new FormData();

          if (!pre_event[0]) {
            bodyFormData.append("pre_event_file", pre_event[1]);
          } else {
            bodyFormData.append("pre_event", pre_event[1]);
          }

          bodyFormData.append("location_id", 1);
          bodyFormData.append("language", "en");
          bodyFormData.append("client_case", Results[0][1]);
          bodyFormData.append("client_type", Results[1][1]);
          bodyFormData.append("jurisdiction", Results[2][1]);
          if (lawyer_name) bodyFormData.append("lawyer", JSON.stringify(lawyer_name));
          if (judge_name) bodyFormData.append("judge", JSON.stringify(judge_name));
          if (this.witness_opposing.every(e => e.name !== '' && e.statement !== '')) {
            const witness_opposing_array = Results[5][1].map((item) => {
              return JSON.stringify({[item.name]: item.statement});
            });
            for (let i = 0; i < witness_opposing_array.length; i++) {
              bodyFormData.append("witness_opposing[]", witness_opposing_array[i]);
            }
          }
          if (this.witness_client.every(e => e.name !== '' && e.statement !== '')) {
            console.log(Results[4][1]);
            const witness_client_array = Results[4][1].map((item) => {
              return JSON.stringify({[item.name]: item.statement});
            });
            for (let i = 0; i < witness_client_array.length; i++) {
              bodyFormData.append("witness_client[]", witness_client_array[i]);
            }
          }
          // bodyFormData.append("evidence_client", evidence_client_file);
          // bodyFormData.append("evidence_opposing", evidence_opposing_file);
          if (client_evidences.length > 0) { 
            for (let i = 0; i < client_evidences.length; i++) {
              bodyFormData.append('evidence_client_file[]', client_evidences[i]); // Append File object, not just name
            }
          }

          if (adverse_evidences.length > 0) {
            for (let i = 0; i < adverse_evidences.length; i++) {
              bodyFormData.append('evidence_opposing_file[]', adverse_evidences[i]); // Append File object, not just name
            }
          }

          // Reset file inputs
          this.pre_event_file = null;
          this.$refs.PreEventFile.value = null;
          for (let [key, value] of bodyFormData.entries()) {
            console.log(key, value);
          }
          // Call the API
          const response = await LegalExpertSystem.LesCreatePreTrialFormData(bodyFormData);
          this.$router.push({
            name: "LegalExpertSystemPreTrialResult",
            params: {
              id: response.data.data?.id,
              category: "preTrial"
            }
          });
        }
      } catch (error) {
        console.error("Validation or submission error:", error);
        this.toastError("An unexpected error occurred.");
      } finally {
        this.isSubmitting = false;
        console.log("Finally", this.isSubmitting);
      }
    },
    increment(flag) {
      const KEY = this.dataKey[flag];
      this[KEY.num] = (parseInt(this[KEY.num]) || 0) + 1;
      this.updateArr(flag);
    },
    decrement(flag) {
      const KEY = this.dataKey[flag];
      if (this[KEY.num] > 0) {
        this[KEY.num] = (parseInt(this[KEY.num]) || 0) - 1;
        this.updateArr(flag);
      }
    }, 
  },

  watch: {
    isAll() {
      this.selectedCourts = [];
      this.choose_court_list = [];
    },
  },
};
</script>

<style>
.nexcard {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  /* padding: 1.5rem; */
}

hr {
  width: 60px;
  margin: 1rem 0.5rem 1rem 0.5rem;
}
.step__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
}
.step__col {
  min-width: 20px;
  text-align: start;
  user-select: none;
}

.step__name {
  white-space: pre;
  margin-left: 0.5rem;
}

.general_container {
  margin: 3rem 2rem;
}

header.card-header {
  padding: 0px !important;
}

.accordion-header {
  background-color: transparent;
  width: 100%;
  height: 100%;
  border-color: transparent;
  color: black;
  text-align: left;
}
.accordion-header:hover {
  background-color: transparent;
}

.card-body {
  padding-bottom: 0px;
}

.card-header[aria-expanded="true"] {
  background-color: black;
}

.accordion-header[aria-expanded="true"] .arrow-icon {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.accordion-header[aria-expanded="false"] .arrow-icon {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.arrow-icon {
  display: none;
  display: block;
  -webkit-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  color: black;
}

.name {
  display: flex;
  align-items: center;
  padding: 0.4rem;
  flex-grow: 1;
  color: black !important;
}
/* 
.accordion-header:hover .material-symbols-outlined {
  color: white;
} */

.redbtn {
  padding-top: 2px;
}

.redbtn:hover {
  color: red;
  background-color: transparent;
}

.bi {
  font-size: 22px;
}

.court_badge {
  max-width: 98%;
  border: none;
  background: #d2d2d2;
  color: black;
  border-radius: 20px;
  font-size: 14px;
  padding: 0.2rem 0.5rem;
  width: max-content;
}

.court_badge .material-icons-outlined {
  font-size: 14px;
  translate: 0.2rem 0.1rem;
}

.juris-modal {
  max-height: 425px;
  overflow-y: auto;
  overflow-x: hidden;
}

.bi-info-circle {
  width: 1.5%;
}

.span_badge {
  border: none;
  color: black;
  border-radius: 20px;
  font-size: 17px;
  padding: 0.1rem 0.2rem;
}

.form-control::file-selector-button {
  background-color: #9f9fa09a;
  border: 1px solid #9f9fa09a;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}
</style>

<style>
.LESInputTooltip .tooltip-inner {
  max-width: 500px;
}
</style>
